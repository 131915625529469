import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TokenBannerImage from "../assets/images/crypto.png";
import Layout from "../Components/Layout/Layout";
import { Chart } from "react-google-charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const AmiToken = (props) => {
  const [pieData, setPieData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const data = [
    { name: "Private Placement", value: "7", color: "#FB7A21" },
    { name: "Seed Round", value: "10", color: "#f87171" },
    { name: "Community Placement", value: "23", color: "#0082bf" },
    { name: "Core Team", value: "17.5", color: "#722ed1" },
    { name: "Technology Ops", value: "2.5", color: "#b8dfd8" },
    { name: "Data Farming Reward", value: "12.3", color: "#007afc" },
    { name: "Ecosystems Adoption Rewards", value: "12.7", color: "#52057b" },
    { name: "Contingency", value: "10", color: "#7ab4b7" },
    {
      name: "Partnership, Market Making & Listing",
      value: "5",
      color: "#d5bfbf",
    },
  ];

  useEffect(() => {
    let tempPieData = [];
    let tempColorData = [];
    let teampData = data;
    teampData.map((aa) => {
      return (
        tempPieData.push([aa.name, parseFloat(aa.value)]),
        tempColorData.push(aa.color)
      );
    });

    if (tempPieData.length > 0 && tempColorData.length > 0) {
      setPieData([...tempPieData]);
      setColorData([...tempColorData]);
    }
  }, []);

  const BannerSection = () => {
    return (
      <>
        <section className="page-banner pt-0 lg:pt-20 ">
          <div className="px-6 pt-10 max-w-6xl mx-auto">
            <div className="block lg:grid grid-cols-12 gap-0  flex-wrap mx-4 items-center">
              <div className=" px-4 flex-1 lg:col-span-7">
                <div className="pages-banner-header-text text-2xl lg:text-4.3xl  ">
                  AMY Token
                </div>
                <div className="pages-banner-paragraph-text pb-9 lg:pb-0">
                  Get Amy to avail exclusive rewards.
                </div>
              </div>
              <div className="flex-1  px-4 lg:col-span-5 ">
                <img
                  src={TokenBannerImage}
                  alt="img"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  const BenefitsSection = () => {
    return (
      <>
        <section className="px-3.5 pt-10 max-w-6xl mx-auto ">
          <div className="token_area extra-pb ">
            <div className="row">
              {/* <div className="col-lg-10 offset-lg-1"> */}
              <div>
                <div className="tittle text-center pb-2">
                  <h4 className="text-center">
                    AMY currency opens up the VIP zone entrances with many
                    advantageshe new way out will boost VIP nightlife
                    tremendously. Have some fantastic nightclub times with AMY .
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="">
                <div className="row flex-row  lg:grid grid-cols-3 ">
                  <div className="lg:px-6 px-0 mb-4 mt-1">
                    <div className="token-box">
                      <div className="content">
                        <h3>
                          Access to the <span> Amethhyyst </span>club's VVIP
                          service
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="lg:px-6 px-0 mb-4 mt-1">
                    <div className="token-box">
                      <div className="content">
                        <h3>
                          Get
                          <span> AMY </span>to avail exclusive rewards.
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="lg:px-6 px-0 mb-4 mt-1">
                    <div className="token-box">
                      <div className="content">
                        <h3>
                          Get a special <span> discount </span>on food.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  const TokenAllocations = () => {
    return (
      <>
        <section className=" token_area bg-white">
          <div className="px-3.5 pt-10 max-w-6xl mx-auto ">
            <div className="row mb-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="tokenmetrics_block">
                  <div className="flex-row align-items-center">
                    <div className="col-lg-12">
                      <div className="text-center">
                        <h2 className="pb-0 mb-6">
                          Token supply : 200 Million
                        </h2>
                        <p className="text-center gray_text text-base link-break-out">
                          Smart Contract Address :
                          <a
                            href="https://xdc.network/token/xdc5d5f074837f5d4618b3916ba74de1bf9662a3fed"
                            className="text-blue-700"
                          >
                            xdc5d5f074837f5d4618b3916ba74de1bf9662a3fed
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="tittle text-center pb-1">
                  <h2 className="text-center">Token Allocation</h2>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-12 pb-4">
              <div className="col-span-5 ">
                <Chart
                  chartType="PieChart"
                  data={[["Allocation", "Percentage"], ...pieData]}
                  options={{
                    legend: "none",
                    backgroundColor: "bg-transperent",
                    colors: colorData,
                  }}
                  width="300px"
                  height="300px"
                  legendToggle
                />
              </div>
              <div className="col-span-7 text-left pl-0 lg:pl-3 pr-0 lg:pr-3">
                <ul className="legends list-icon gray_text">
                  {data.length > 0 &&
                    data.map((item, inx) => (
                      <li key={inx}>
                        <FontAwesomeIcon
                          className="mr-1"
                          icon={faCircle}
                          style={{ color: item.color }}
                        />
                        {item.name} - {item.value}%
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  const ExchangeList = () => {
    return (
      <>
        <section class="token_area exchange-listing bg-white">
          <div class="px-3.5 pt-10 max-w-6xl mx-auto ">
            <div class="row">
              <div class="col-lg-10 offset-lg-1">
                <div class="tittle text-left pb-4">
                  <h2>Exchange Listing Resource</h2>
                  <p className="gray_text">
                    Details require to list AMI token with exchange.
                  </p>
                  <p className="gray_text">
                    AMI is a XRC-20 utility token deployed on XinFin Network.
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="">
                <ul class="list-block">
                  <li class="">
                    <span>Project Name</span>
                    <p className="gray_text">AMI Network</p>
                  </li>
                  <li class="">
                    <span>Token Name</span>
                    <p className="gray_text">AMI</p>
                  </li>
                  <li class="">
                    <span>Token Symbol</span>
                    <p className="gray_text">AMI</p>
                  </li>
                  <li class="">
                    <span>Total Supply</span>
                    <p className="gray_text">500 Million</p>
                  </li>
                  <li class="">
                    <span>Decimals</span>
                    <p className="gray_text">18</p>
                  </li>
                  <li class="">
                    <span>Project website</span>
                    <p class="link-break-out">
                      <a
                        href="https://storx.tech"
                        className="text-blue-700"
                        // target="_blank"
                      >
                        https://AMI.tech
                      </a>
                    </p>
                  </li>
                </ul>
              </div>

              <div class=" mt-12 px-3.5 text-bold">
                <p class="link-break-out gray_text">
                  XDC3 SDK:
                  <a
                    href="https://www.npmjs.com/package/xdc3"
                    className="text-blue-700"
                    // target="_blank"
                  >
                    https://www.npmjs.com/package/xdc3
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  Blockchain Endpoint:
                  <a
                    href="https://rpc.xinfin.network"
                    className="text-blue-700"
                    // target="_blank"
                  >
                    https://rpc.xinfin.network
                  </a>
                </p>
                <p class="link-break-out fw-5 gray_text">
                  XRC-20 token build on
                  <a href="https://xinfin.network/" className="text-blue-700">
                    XinFin Network
                  </a>
                </p>
                <p class="link-break-out fw-6 gray_text">
                  Contract Address:
                  <a
                    href="https://xdc.network/token/xdc5d5f074837f5d4618b3916ba74de1bf9662a3fed"
                    className="text-blue-700"
                  >
                    https://xdc.network/token/xdc5d5f074837f5d4618b3916ba74de1bf9662a3fed
                  </a>
                </p>
                <p class="gray_text">
                  (Please cross check the AMY Address before any transfers are
                  made. Tokens should never be sent to Smart Contract Address,
                  Any tokens sent to smart contract cannot be retrieved.)
                </p>
                <p class="link-break-out gray_text">
                  Github:
                  <a
                    href="https://github.com/StorXNetwork"
                    className="text-blue-700"
                    // target="_blank"
                  >
                    https://github.com/StorXNetwork
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  White Paper:
                  <a
                    href="https://storx.tech/storx_whitepaper_v1.pdf"
                    // target="_blank"
                    className="text-blue-700"
                  >
                    https://storx.tech/storx_whitepaper_v1.pdf
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  Telegram:
                  <a
                    href="https://t.me/StorXNetwork"
                    className="text-blue-700"
                    // target="_blank"
                  >
                    https://t.me/AMINetwork
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  YouTube:
                  <a
                    href="https://www.youtube.com/channel/UC5vUiX1eINl0gs6T3mS1CxA"
                    // target="_blank"
                    className="text-blue-700"
                  >
                    https://www.youtube.com/channel/UC5vUiX1eINl0gs6T3mS1CxA
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  Twitter:
                  <a
                    href="https://twitter.com/StorXNetwork"
                    className="text-blue-700"
                    // target="_blank"
                  >
                    https://twitter.com/AMINetwork
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  LinkedIn:
                  <a
                    href="https://www.linkedin.com/company/storxnetwork/"
                    // target="_blank"
                    className="text-blue-700"
                  >
                    https://www.linkedin.com/company/AMInetwork/
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  Facebook:
                  <a
                    href="https://www.facebook.com/StorXNetwork"
                    // target="_blank"
                    className="text-blue-700"
                  >
                    https://www.facebook.com/AMINetwork
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  Medium:
                  <a
                    href="https://medium.com/storx-network"
                    className="text-blue-700"
                    // target="_blank"
                  >
                    https://medium.com/AMI-network
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  Reddit:
                  <a
                    href="https://www.reddit.com/r/StorXNetwork"
                    // target="_blank"
                    className="text-blue-700"
                  >
                    https://www.reddit.com/r/AMINetwork
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  Discord:
                  <a
                    href="https://discord.gg/ha4Jufj2Nm"
                    className="text-blue-700"
                    // target="_blank"
                    rel="noreferrer"
                  >
                    https://discord.gg/ha4Jufj2Nm
                  </a>
                </p>
                <p class="link-break-out gray_text">
                  AMI Logo:
                  <a
                    href="assets/images/storx-logo/storx-brand-assets.zip"
                    // target="_blank"
                    className="text-blue-700"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                  AMI Logo in ai, eps, svg, and png formats.
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  return (
    <>
      <Layout location={props.location}>
        <Helmet>
          {/* <title>{data.page.meta_title || data.page.title}</title> */}
          {/* <meta
      property="og:title"
      content={data.page.meta_title || data.page.title}
    /> */}
          <meta
            name="theme-color"
            content="#144a95"
            media="(prefers-color-scheme: light)"
          />
          <meta
            name="theme-color"
            content="#144a95"
            media="(prefers-color-scheme: dark)"
          />
          <meta name="theme-color" content="#144a95" />
          <meta content="noindex" />
          <meta content="nofollow" />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
          />
          {/* <meta name="description" content={data.page.meta_desc} />
    <meta property="og:description" content={data.page.meta_desc} />
    <meta property="twitter:description" content={data.page.meta_desc} /> */}
        </Helmet>
        <div className=" grey_bg ">
          <BannerSection />
          <BenefitsSection />
          <TokenAllocations />
          <ExchangeList />
        </div>
      </Layout>
    </>
  );
};

export default AmiToken;
